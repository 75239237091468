import React from "react";
import { graphql } from "gatsby";
import { Location } from "@reach/router";
import qs from "qs";

// import PageHeader from '../components/PageHeader'
import PostSection from "../components/PostSection";
import PostCategoriesNav from "../components/PostCategoriesNav";
import Layout from "../layout/LayoutBootstrap";

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
const byDate = (posts) => {
  const now = Date.now();
  return posts.filter((post) => Date.parse(post.date) <= now);
};

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === "postCategories";
  const byCategoryFilter = (post) =>
    post.categories &&
    post.categories.filter((cat) => cat.category === title).length;

  return isCategory ? posts.filter(byCategoryFilter) : posts;
};

// Export Template for use in CMS preview
const BlogIndexTemplate = ({
  title,
  subtitle,
  featuredImage,
  posts = [],
  postCategories = [],
  enableSearch = true,
  contentType,
}) => (
  <Location>
    {({ location }) => {
      let filteredPosts =
        posts && !!posts.length
          ? byCategory(byDate(posts), title, contentType)
          : [];

      let queryObj = location.search.replace("?", "");
      queryObj = qs.parse(queryObj);

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase();
        filteredPosts = filteredPosts.filter((post) =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        );
      }

      return (
        <main className="Blog">
          {!!postCategories.length && (
            <section className="section thin">
              <div className="container">
                <PostCategoriesNav enableSearch categories={postCategories} />
              </div>
            </section>
          )}

          {!!posts.length && (
            <section className="section">
              <div className="container">
                <PostSection posts={filteredPosts} />
              </div>
            </section>
          )}
        </main>
      );
    }}
  </Location>
);

// Export Default BlogIndex for front-end
const BlogIndex = ({ data: { page, posts, postCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
    feature={page.frontmatter.feature}
    language={page.frontmatter.language}
  >
    <BlogIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      posts={posts.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields,
      }))}
      postCategories={postCategories.edges.map((post) => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields,
      }))}
    />
  </Layout>
);

export const pageQuery = graphql`query BlogIndex($id: String!, $language: String!) {
  page: markdownRemark(id: {eq: $id}) {
    ...Meta
    ...FeatureImage
    fields {
      contentType
      localizedPath
    }
    excerpt(pruneLength: 280)
    frontmatter {
      title
      templateKey
      subtitle
      language
      path
      featuredImage {
        childImageSharp {
          gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      contentType
    }
  }
  posts: allMarkdownRemark(
    filter: {fields: {contentType: {eq: "posts"}}, frontmatter: {language: {eq: $language}}}
    sort: {order: DESC, fields: [frontmatter___date]}
  ) {
    edges {
      node {
        excerpt(pruneLength: 280)
        fields {
          slug
          localizedPath
        }
        frontmatter {
          title
          path
          date
          categories {
            category
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(quality: 85, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  postCategories: allMarkdownRemark(
    filter: {fields: {contentType: {eq: "postCategories"}}, frontmatter: {language: {eq: $language}}}
    sort: {order: ASC, fields: [frontmatter___title]}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          path
        }
      }
    }
  }
}
`;
export default BlogIndex;
